style = $.cookie('style');
font = $.cookie('font');
spacing = $.cookie('spacing');
if (spacing === undefined) {
    spacing = "small";
}


if (style === "wcagBlack") {
    wcagBlackStyle(document.createEvent('Event'));
}

if (style === "wcagYellow") {
    wcagYellowStyle(document.createEvent('Event'));
}

if (style === "wcagBlackYellow") {
    wcagBlackYellowStyle(document.createEvent('Event'));
}

if (font === "medium") {
    mediumFont(document.createEvent('Event'));
}
if (font === "large") {
    largeFont(document.createEvent('Event'));
}

function checkSpacing(){
    if (spacing === "large") {
        document.documentElement.classList.add('spacing')
    }else{
        document.documentElement.classList.remove('spacing')
    }

}




function normalStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet.css');
    $.cookie('style', 'normal', {
        expires: 365,
        path: '/'
    });
}

function wcagBlackStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-black.css');
    $.cookie('style', 'wcagBlack', {
        expires: 365,
        path: '/'
    });
}

function wcagYellowStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-yellow.css');
    $.cookie('style', 'wcagYellow', {
        expires: 365,
        path: '/'
    });
}

function wcagBlackYellowStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-blackYellow.css');
    $.cookie('style', 'wcagBlackYellow', {
        expires: 365,
        path: '/'
    });
}


function smallFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-small';
    $.cookie('font', 'small', {
        expires: 365,
        path: '/'
    });
    checkSpacing();


}

function mediumFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-medium';
    $.cookie('font', 'medium', {
        expires: 365,
        path: '/'
    });
    checkSpacing();


}

function largeFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-large';
    $.cookie('font', 'large', {
        expires: 365,
        path: '/'
    });
    checkSpacing();
}


function setSpacing(event) {
    event.preventDefault();

    if (spacing === "small") {
        document.documentElement.classList.add('spacing')
        $.cookie('spacing', 'large', {
            expires: 365,
            path: '/'
        });
        spacing = "large";
    } else {
        document.documentElement.classList.remove('spacing')
        $.cookie('spacing', 'small', {
            expires: 365,
            path: '/'
        });
        spacing = "small";
    }

}




